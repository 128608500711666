





























































import {Component, Vue} from 'vue-property-decorator';
import SettingsItem from '@/components/SettingsItem.vue';
import DaySettings from '@/components/DaySettings.vue';
import Selector from '@/components/Selector.vue';
import {settingsStore} from '@/store/modules/settings';
import {computeTimeWithOffset, timeZoneAndTime} from '@/utils/helpers';
import Notifications from '@/components/mixins/Notifications';
import axios from '@/plugins/axios';
import {mixins} from 'vue-class-component';
const env: any = process.env;

@Component({
  name: 'localisation',
  components: {Selector, DaySettings, SettingsItem},
  filters: {timeZoneAndTime, computeTimeWithOffset}
})
export default class Localisation extends mixins(Vue, Notifications) {

  dirty: boolean = false;
  location = '';
  timezone = ''; 
  isLoading: boolean = false;

  get date() {
    return new Date()
  }

  get timeZone() {
    return settingsStore.timeZone
  }

  set timeZone(index: number) {
    settingsStore.changeTimeZone(index);
    settingsStore.updateAutoArchiveMailedDate();
  }

  get timeZones() {
    return settingsStore.timeZones
  }

  mounted(){
    if(settingsStore.businessSettings && settingsStore.businessSettings?.timeZoneIndex !== -1){
      console.log('TimeZone already set')
    }else {
      this.getUserLocation()
    }
  }

  getUserLocation() {
    if (navigator.geolocation) {
      // Make sure both success and error handlers are bound correctly
      this.isLoading = true
      navigator.geolocation.getCurrentPosition(
        this.handleLocationSuccess, 
        this.handleLocationError
      );
    } else {
      this.showIssue("Geolocation is not supported by this browser.");
    }
  }

  // Define the success and error handlers as arrow functions to automatically bind 'this'
  handleLocationSuccess = (position: GeolocationPosition) => {
    // Handle location success logic here
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;

    // Fetch time zone based on coordinates
    this.fetchUserTimeZone(lat, lon);
  };

  handleLocationError = (error: GeolocationPositionError) => {
    // Handle location error logic here
    console.log("handleLocationError: ", error);
    if(error.message){
      this.showIssue(`${error.message}`);
    }else {
      this.showIssue('Unable to retrieve your location');
    }
    this.errorLocationAccess()   
  };

  async errorLocationAccess() {
    this.isLoading = false
  }

  async showErrorMessage(message) {
    this.showIssue(message);
  }

  async fetchUserTimeZone(lat: number, lon: number) {

    try{
    
      axios.defaults.timeout = 9 * 60 * 1000;
      const result = await axios.get(env.VUE_APP_API_FUNCTION_URL + '/apiGetUserTimeZone', {
        params: {
          lat: lat,
          lon: lon
        }
      });

      if (result.status === 200) {  
        if(result.data.status === 200 && result.data.errorCode === '0'){
          
        const rawOffset = result.data.rawOffset;
        const dstOffset = result.data.dstOffset;

        // Calculate the total current offset (in hours)
        const totalOffset = (rawOffset + dstOffset) / 3600;  // Convert seconds to hours

          // Now match it with your array based on the offset
        const matchedTimeZone = settingsStore.timeZones.find(tz => tz.numvalue === totalOffset);

        if (matchedTimeZone) {
          if(matchedTimeZone.index && matchedTimeZone.index != null){
            await settingsStore.changeTimeZone(matchedTimeZone.index);
          }
        } else {
          console.log('No matching timezone found.');
        }

        }else{
          console.error('Success with error code')
        }        
      }else{
        console.error('Error code: ' + result.status)
      }

    } catch (err) {   
      console.error(err)
      this.showErrorMessage('Cant get time zone data')
    } 

    this.isLoading = false

  }

}
