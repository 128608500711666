






















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'selector'})
export default class Selector extends Vue {
    @Prop({default: []}) options?: any[];
    @Prop({default: '200px'}) width?: string;
    @Prop({default: '56px'}) height?: string;
    @Prop() label?: string;

    selectedOption?: any

    get style() {
        return {
            width: '200px',
            height: '56px'
        };
    }

    closeAllSelect(elmnt) {
        /*a function that will close all select boxes in the document,
        except the current select box:*/
        let x, y, i, arrNo: any;
        x = this.$refs['select-items'];
        y = this.$refs['select-selected'];
        if (elmnt == y) {
            // arrNo.push(i);
        } else {
            y.classList.remove('select-arrow-active');
        }
        for (i = 0; i < x.length; i++) {
            // if (arrNo.indexOf(i)) {
            //     x[i].classList.add('select-hide');
            // }
        }
    }

    selectClickListener(ev) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        ev.stopPropagation();
        const a: any = this.$refs['select-selected'];
        const b: any = this.$refs['select-items'];
        // this.closeAllSelect(a);
        b.classList.toggle('select-hide');
        a.classList.toggle('select-arrow-active');
    };

    itemClickListener(optionName) {
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        this.selectedOption = optionName
        const c: any = this.$refs[optionName];
        let y, i, k, s, h;
        s = this.$refs['select'];
        h = this.$refs['select-selected'];
        for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == c.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = c.innerHTML;
                const parent: any = this.$refs['select-items'];
                y = parent.getElementsByClassName('same-as-selected');
                for (k = 0; k < y.length; k++) {
                    y[k].removeAttribute('class');
                }
                c.setAttribute('class', 'same-as-selected');
                break;
            }
        }
        h.click();
    };

    mounted() {
        // const ctx = this;
        // const selector: any = this.$refs['custom-select'];
        // const selElmnt: any = this.$refs['select'];

        /*for each element, create a new DIV that will act as the selected item:*/
        // const a: any = document.createElement('div');
        // a.setAttribute('class', 'select-selected');
        // a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        // selector.appendChild(a);
        /*for each element, create a new DIV that will contain the option list:*/
        // const b = document.createElement('div');
        // b.setAttribute('class', 'select-items select-hide');
        // for (let j = 1; j < selElmnt.length; j++) {
        //     /*for each option in the original select element,
        //     create a new DIV that will act as an option item:*/
        //     const c: any = document.createElement('div');
        //     c.innerHTML = selElmnt.options[j].innerHTML;
        //     const itemClickListener = function (ev) {
        //         /*when an item is clicked, update the original select box,
        //         and the selected item:*/
        //         let y, i, k, s, h;
        //         s = c.parentNode.parentNode.getElementsByTagName('select')[0];
        //         h = c.parentNode.previousSibling;
        //         for (i = 0; i < s.length; i++) {
        //             if (s.options[i].innerHTML == c.innerHTML) {
        //                 s.selectedIndex = i;
        //                 h.innerHTML = c.innerHTML;
        //                 y = c.parentNode.getElementsByClassName('same-as-selected');
        //                 for (k = 0; k < y.length; k++) {
        //                     y[k].removeAttribute('class');
        //                 }
        //                 c.setAttribute('class', 'same-as-selected');
        //                 break;
        //             }
        //         }
        //         h.click();
        //     };
        //     c.addEventListener('click', itemClickListener);
        //     b.appendChild(c);
        // }
        // selector.appendChild(b);
        // const selectClickListener = function (ev) {
        //     /*when the select box is clicked, close any other select boxes,
        //     and open/close the current select box:*/
        //     ev.stopPropagation();
        //     ctx.closeAllSelect(a);
        //     a.nextSibling.classList.toggle('select-hide');
        //     a.classList.toggle('select-arrow-active');
        // };
        // a.addEventListener('click', selectClickListener);
    }
}
