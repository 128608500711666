



































































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {AppointDay} from '@/domain/model/types';
import rfdc from 'rfdc';

const clone = rfdc({proto: true})

@Component({
  name: 'day-settings'
})
export default class DaySettings extends Vue {
  @Prop() day!: AppointDay;
  @Prop({default: false}) disabled?: boolean;
  @Prop({type: String}) excludeFrom
  @Prop({type: String}) excludeTo

  times: any[] = [
    {
      value: '12:00 AM',
      disabled: false
    },
    {
      value: '1:00 AM',
      disabled: false
    },
    {
      value: '2:00 AM',
      disabled: false
    },
    {
      value: '3:00 AM',
      disabled: false
    },
    {
      value: '4:00 AM',
      disabled: false
    },
    {
      value: '5:00 AM',
      disabled: false
    },
    {
      value: '6:00 AM',
      disabled: false
    },
    {
      value: '7:00 AM',
      disabled: false
    },
    {
      value: '8:00 AM',
      disabled: false
    },
    {
      value: '9:00 AM',
      disabled: false
    },
    {
      value: '10:00 AM',
      disabled: false
    },
    {
      value: '11:00 AM',
      disabled: false
    },
    {
      value: '12:00 PM',
      disabled: false
    },
    {
      value: '1:00 PM',
      disabled: false
    },
    {
      value: '2:00 PM',
      disabled: false
    },
    {
      value: '3:00 PM',
      disabled: false
    },
    {
      value: '4:00 PM',
      disabled: false
    },
    {
      value: '5:00 PM',
      disabled: false
    },
    {
      value: '6:00 PM',
      disabled: false
    },
    {
      value: '7:00 PM',
      disabled: false
    },
    {
      value: '8:00 PM',
      disabled: false
    },
    {
      value: '9:00 PM',
      disabled: false
    },
    {
      value: '10:00 PM',
      disabled: false
    },
    {
      value: '11:00 PM',
      disabled: false
    },
    {
      value: '11:59 PM',
      disabled: false
    }
  ];
  timesFrom: any[] = clone(this.times).slice(0, this.times.length - 1)
  timesTo: any[] = clone(this.times).slice(1, this.times.length)

  get dayFrom() {
    return this.day.start
  }

  set dayFrom(value: string) {
    this.day.from = value
    this.day.start = value
    this.timesTo.forEach((item, index) => item.disabled = (
        index < this.excludeFromIndex
        || index >= this.excludeToIndex
        || index < this.excludeDayFromIndex))

  }

  get dayTo() {
    return this.day.end
  }

  set dayTo(value: string) {
    this.day.to = value
    this.day.end = value
    this.timesFrom.forEach((item, index) => item.disabled = (
        index < this.excludeFromIndex
        || index >= this.excludeToIndex
        || index >= this.excludeDayToIndex))
  }

  get excludeDayFromIndex() {
    return this.times.findIndex(item => item.value === this.dayFrom)
  }

  get excludeDayToIndex() {
    return this.times.findIndex(item => item.value === this.dayTo)
  }

  get excludeFromIndex() {
    return this.times.findIndex(item => item.value === this.excludeFrom)
  }

  get excludeToIndex() {
    return this.times.findIndex(item => item.value === this.excludeTo)
  }

  created() {
    // TODO: incorrect validated disabled items
    if (this.day) {
      if (!this.dayFrom) {
        this.dayFrom = this.timesFrom[0].value
      }
      if (!this.dayTo) {
        this.dayTo = this.timesTo[this.timesTo.length - 1].value
      }
    }
    const {timesFrom, timesTo, excludeFrom, excludeTo} = this
    if (excludeFrom && excludeTo) {
      timesFrom.forEach((item, index) => item.disabled = (
          index < this.excludeFromIndex
          || index >= this.excludeToIndex
          || index >= this.excludeDayToIndex))
      timesTo.forEach((item, index) => item.disabled = (
          index < this.excludeFromIndex
          || index >= this.excludeToIndex
          || index < this.excludeDayFromIndex))
    }
  }
};
