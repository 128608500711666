












import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    name: 'settings-item'
})
export default class SettingsItem extends Vue {
    @Prop() title?: string;
    @Prop() subtitle?: string;
    @Prop({default: 'top'}) palign?: string;
};
