import { render, staticRenderFns } from "./Localisation.vue?vue&type=template&id=c948d6ae&scoped=true&"
import script from "./Localisation.vue?vue&type=script&lang=ts&"
export * from "./Localisation.vue?vue&type=script&lang=ts&"
import style0 from "./Localisation.vue?vue&type=style&index=0&id=c948d6ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c948d6ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VCol,VImg,VRow,VSelect,VSheet,VToolbarTitle})
